exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-access-tsx": () => import("./../../../src/pages/access.tsx" /* webpackChunkName: "component---src-pages-access-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-members-tsx": () => import("./../../../src/pages/members.tsx" /* webpackChunkName: "component---src-pages-members-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-publication-tsx": () => import("./../../../src/pages/publication.tsx" /* webpackChunkName: "component---src-pages-publication-tsx" */),
  "component---src-pages-research-ea-detail-2024-tsx": () => import("./../../../src/pages/research/eaDetail2024.tsx" /* webpackChunkName: "component---src-pages-research-ea-detail-2024-tsx" */),
  "component---src-pages-research-ea-detail-tsx": () => import("./../../../src/pages/research/eaDetail.tsx" /* webpackChunkName: "component---src-pages-research-ea-detail-tsx" */),
  "component---src-pages-research-tsx": () => import("./../../../src/pages/research.tsx" /* webpackChunkName: "component---src-pages-research-tsx" */)
}

